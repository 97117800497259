.font-weight-600 {
  font-weight: 600;
}

.page-break {
  page-break-before: always; /* Forces a page break before this element */
  /* Or use page-break-after if you want the break to occur after the element */
  /* Or use page-break-inside to avoid breaking within the element */
}

.custom-legend-chart .apexcharts-legend {
  display: flex;
  flex-direction: column;
}

.custom-legend-chart .apexcharts-legend-text {
  text-align: left !important;
}


/* App.css */
.dropdown-right .ant-dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.custom-table-header {
  position: relative; /* Ensure z-index works */
  z-index: 0;         /* Set z-index to 0 */
}

.ql-container.ql-disabled {
  background-color: #e9ecef; /* Light gray background */
  color: #000 !important; /* Gray text color */
  cursor: not-allowed; /* "Not allowed" cursor */
}

.ql-toolbar.ql-disabled {
  background-color: #e9ecef; /* Light gray toolbar */
  pointer-events: none; /* Disable toolbar interactions */
}

/* If you want to make text inside the editor look disabled */
.ql-editor.ql-disabled {
  color: #000; /* Gray text color */
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}

.linkBox_Ellipsis{
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;  
}
.linkBox{
  border: 1px solid  #ced4da;
  padding: 0.5rem;
  border-radius: 0.2rem;
}

@keyframes wave {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(30deg);
  }
}

.notification-icon {
  position: relative;
  font-size: 24px; 
  color: #000; 
}

.notification-count {
  position: absolute;
  top: -10;
  right: -10;
  background: #1ea74a;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
  line-height: 1;
  transform: translate(50%, -50%);
}


.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  transition: background-color 0.3s;
  text-decoration: none;
  color: inherit;
  max-width: 350px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 18px;
}

.dropdown-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dropdown-item-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.dropdown-item-body {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.dropdown-item-time {
  font-size: 12px;
  color: #999;
  float:left;
}


.dropdown-item-mark {
  font-size: 14px;
  color:blue;
  float:right;
}


.dropdown-item-unread .dropdown-item-icon {
  background-color: #007bff;
  color: #fff;
}


.bell_icon{
  font-size:22px !important
}

.team-change-table th {
  white-space: nowrap;
}

.team-change-table th {
  vertical-align: middle;
}

.team-change-table .text-ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.guZdik {
  border: 1px solid #000 !important;
}

.sc-aXZVg .dtlKip {
  border: 1px solid #000 !important;
}

.loginheight {
  height: 100vh;
}


.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.clientdetails {
  display: flex;
  justify-content: center;

}

.box_sh {
  color: #027a48;
  border-bottom: 3px solid #027a48;
  background-color: #fff !important;
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.tab_size {
  height: 3.5em;
  width: 8em;
}

.tab_sizeAT_TL {
  height: 2em;
  width: 3em;
}

.box_tab {
  padding: 0 !important;
  background-color: #E7F8F7 !important;
  border-radius: 12px !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2) !important;
}

.textcolor {
  color: #1ea74a;
}

.table-heading-color {
  background-color: #ABEBC6 !important;
}

.textcolorgreen {
  color: #027a48;
}

.textlightblack {
  color: black;
}

.iconcolor {
  padding: 0.5rem;
  color: #4cd964;
  border-radius: 0.4rem;
}

.paracenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.bg-body-tertiary {
  background-color: white !important;
}

.textcolumntitle {
  color: #475467;
}

.textgrey {
  color: #515151;
}

.textcolorblue {
  color: #103054;
}

.lightgreen {
  color: #07b6af;
}

.bg-lightgreen {
  background-color: #07b6af;
}

.bg_green {
  background-color: #1ea74a;
}

.bg_lightgreen {
  background-color: #ecfdf3;
}

.backgroundlightgreen {
  background-color: #07b6af;
}

.bg-sidebar {
  background-color: #07b6af;
}

.bg_lightgreetable {
  background-color: rgba(7, 182, 175, 1);
}

.boxwidth {
  width: 359px;
  height: 517px;
  top: 157px;
  gap: 25px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.borderlightgreen {
  border: 1px solid #E4E4E7;
}

.bordergreen {
  border: 1px solid #1ea74a;
}

.font14px {
  font-size: 16px;
  font-weight: 500px;
}

.font12px {
  font-size: 12px;
}

.font15px {
  font-size: 15px;
}

.font16px {
  font-size: 16px;
}

.font12px {
  font-size: 12px;
}

.font20px {
  font-size: 16px;
}

.font14px {
  font-size: 14px;
}

.mb-6 {
  margin-bottom: 38px;
}

.mt-6 {
  margin-top: 38px;
}

.btnhovergrey:hover {
  background-color: rgba(37, 37, 37, 0.1);
  color: #fff;
  border-radius: 14px;
}

.evidence-button {
  width: 70%;
  display: flex;
  justify-content: end;
  margin-left: 10%;
}

.custom-disabled-date-picker .ant-picker-input>input[disabled] {
  color: black;
}

.otpinput::-webkit-inner-spin-button,
.otpinput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loginbg {
  height: 100%;
  width: 100%;
  background-color: hsla(200, 40%, 30%, 0.4);
  background-image: url("https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png"),
  url("https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png"),
  url("https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png"),
  url("https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png");
  background-repeat: repeat-x;
  background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
  background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
  animation: 80s para infinite linear;
}

.gradient_client {
  background-image: linear-gradient(to right, #bdf1f1, #f1dfbb);
}

.gradient_login {
  background-image: linear-gradient(to right, #A9F1DF, #FFBBBB);
}

@keyframes para {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%,
      400px 0;
  }
}

.blurloginbox {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.stat_text {
  font-size: 18px;
  font-weight: 700px;
}

.table-pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.bg-box-gradient {
  background-image: linear-gradient(to right, #76d8ee, #8ad039);
}

.sidenavbar_bg {
  background-color: #fff;
}

.ant-table-tbody>tr:nth-child(even)>td {
  background-color: #EAECF0;
}

.form-container {
  background: linear-gradient(#fff, #fff) padding-box,
  linear-gradient(145deg, transparent 35%, #8ad039, #008c87) border-box;
  border: 2px solid transparent;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background-size: 200% 100%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.inputGroup {
  font-family: "Segoe UI", sans-serif;
  margin: 1em 0 1em 0;
  max-width: 190px;
  position: relative;
}

.text-gradient-earthood {
  background: -webkit-linear-gradient(360deg, #4cd964, #34aadc);
  -webkit-text-fill-color: transparent;
}
.text-earthood {
color: #4cd964
  /* -webkit-text-fill-color: transparent; */
}

.borderfileupload {
  border-radius: 12px;
}

.formclient {
  border: 1px solid rgb(158, 154, 154);
  box-shadow: -15px 15px #002f43;
}

.inputGroup input {
  font-size: 100%;
  padding: 0.8em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 20px;
  width: 100%;
}

.inputGroup label {
  font-size: 100%;
  position: absolute;
  left: 0;
  padding: 0.8em;
  margin-left: 0.5em;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(100, 100, 100);
}

.inputGroup :is(input:focus, input:valid)~label {
  transform: translateY(-50%) scale(0.9);
  margin: 0em;
  margin-left: 1.3em;
  padding: 0.4em;
  background-color: #e8e8e8;
}

.inputGroup :is(input:focus, input:valid) {
  border-color: #07b6af;
}

.formclientbg {
  background-color: #9aedea;
}




.custom-search .ant-input {
  border-radius: 4px;
}

.custom-search .ant-btn {
  background-color: #1ea74a !important;
}







/* media queries for responsive design */
/* sm screen */
@media only screen and (max-width: 415px) {
  .carouselimage {
    max-height: 30vh;
  }

  .tab_dashboard_size {
    height: 100px;
  }

  .stat_text {
    font-size: 15px;
    text-align: center;
    margin: 0.3rem auto 0 auto;
  }

  .font14px1 {
    font-size: 6.5px;
  }

  .tab_dashboard_size5tab {
    height: 90px;
    width: 35px;
  }

  .font14px {
    font-size: 10px;
    text-align: center;
  }

  .font20px {
    font-size: 20px;
    text-align: center;
  }

  .iconcolor {
    display: none;
  }

  .textwelcome {
    font-size: large;
  }

  .tab_size {
    height: 3.8em;
    width: 5em;
    font-size: 1em !important;
    margin: 0 0.6rem !important;
  }
}

@media (min-width: 420px) and (max-width: 920px) {
  .carouselimage {
    max-height: 30vh;
  }

  .tab_dashboard_size {
    height: 107px;
    width: 145.28px;
  }

  .tab_dashboard_size5tab {
    height: 145px;
    width: 115.28px;
  }

  .font14px {
    font-size: 10px;
  }

  .tab_size {
    font-size: 1.3em;
    margin: 0 0.3rem !important;
  }
}

@media (min-width: 1020px) and (max-width: 1250px) {
  .carouselimage {
    max-height: 95vh;
  }

  .tab_dashboard_size {
    height: 125px;
    width: 145px;
  }

  .tab_dashboard_size5tab {
    height: 145px;
    width: 115.28px;
  }

  .tab_size {
    font-size: 1.3em;
  }
}

@media (min-width: 1250px) {
  .carouselimage {
    max-height: 95vh;
  }

  .tab_dashboard_size {

    height: 116px;
    width: 210px;
  }

  .tab_dashboard_size5tab {
    height: 80px;
    width: 180px;
  }

  .tab_dashboard_size5tabTMS {
    height: 80px;
    width: 190px;
    box-sizing: border-box;
  }

  .pttablineheight {
    height: 45px;
  }

  .tab_size {
    font-size: 1.3em;
  }
}

@media only screen and (min-width: 950px) {

}

.multi .dropdown-heading {
  background-color: #E9ECEF !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 45, 45, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-center {
  margin-left: 35%;
}

.dashboardcolor {
  background-color: #fafff9;
}

.card-size {
  height: 25vh;
  width: 20vw
}

.card-color {
  background-color: #5ec576;
}

.heading-size {
  text-align: center;
}

.mycard {
  background-color: #fff;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transition: box-shadow 0.3s, transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mycard_dash {
  background-color: #fff;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 0.5rem;
  transition: box-shadow 0.3s, transform 0.3s;
}

.mycard:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.mycard_dash:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.mycard-content {
  color: #333;
}

.card-hover:hover {
  background-color: rgb(105, 187, 105);
  color: white;
}

.sub-head {
  color: #A6ACAF;
}

.apex-chart {
  width: 100%;
  height: 60%;
}

.cc-review1 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cc-review2 {
  display: flex;
  align-items: center;
  width: 40%;
  margin-bottom: 2rem
}

.cc-review3 {
  margin-right: 0.5rem;
  color: #07b6af;
  font-weight: bold
}

.teamchange_searchbar {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 991px) {
  .apex-chart {
    height: 400px;
  }
}

@media screen and (min-width: 700px) {
  .apex-chart {
    height: 350px;
  }
}

@media screen and (min-width: 370px) {
  .apex-chart {
    height: 300px;
  }
}