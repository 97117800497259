.error{
  color: red;
}


.custom-legend-chart .apexcharts-legend {
  display: flex;
  flex-direction: column;
  text-align: left !important;
}
/* 
.custom-legend-chart .apexcharts-legend-text {
  text-align: left !important;
} */


.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 0rem;}
 
.ant-tabs-ink-bar {
  display: none; 
}
  
.ant-tabs-indicator{
  display: none;
}
 
.ant-tabs.at_tl_dash_tab_align .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 !important;
}

.ant-tabs.at_tl_dash_tab_align > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs.at_tl_dash_tab_align > div > .ant-tabs-nav .ant-tabs-nav-list {
    flex-wrap: wrap !important;
    gap: 0px 25px !important;
    width: 100% !important;
}

.ant-tabs.client_dash_tab_align .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 !important;
}

.ant-tabs.client_dash_tab_align > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs.client_dash_tab_align > div > .ant-tabs-nav .ant-tabs-nav-list {
  flex-wrap: wrap !important;
  gap: 0px 25px !important;
  width: 100% !important;
}

.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list {
  flex-wrap: wrap !important;
  gap: 0px 32px !important;
}

.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 !important;
}

.ant-select-selection-item {
  color:#000 !important;
}

:disabled {
  color:#000 !important;
}

.css-olqui2-singleValue :disabled {
  color:#000 !important;
}
.css-olqui2-singleValue{
  color:#000 !important;
}